<template>
    <section
    id="welcome"
    class="overflow-hidden"
  >
    <v-row no-gutters
    justify="center"
    align="start"
    >   
      <v-col
        class=""
        cols="12"
        md="6"
      >
        <v-row no-gutters
        
        >
        <v-card width="100%" class="accent mx-auto" elevation="0" tile height="56px">
            <div class="text-center  mt-3">
          <base-heading class="info--text">
          {{title}}
          </base-heading> 
            </div>
        </v-card>
<!-- od tego dodane -->
<!-- <div class="mt-3 mx-8 text-justify">
  <base-text class="info--text">{{description}}</base-text>
</div> -->
<!-- do tego miejsca dodane -->
  </v-row>
  <v-row no-gutters align="center">
  <v-container
    fluid
    
  >

<div class="mt-3 mx-8 text-justify">
  <base-text class="info--text">{{description}}</base-text>
</div>



  <!-- tu dodane karuzela -->
  <v-row>
    <v-carousel
    cycle
    hide-delimiter-background
    light
    height="517"
    >
      <v-carousel-item
      v-for="(manu, i) in manual"
      :key="i"
      >
        <v-row>
          <v-col>
            <v-card elevation="0">
              <v-img
                :src="require(`@/assets/${manu.foto}`)"
                height="390"
                width="422"
                class="mx-auto"
              />

<v-col
  cols="10"
  md="8"
  offset="1"
  offset-md="2"
>
                <!-- <v-card-title> -->
                  <base-text>
                    <div class="text-justify" v-text="manu.text" />
                    <!-- {{manu.text}} -->
                  </base-text>
                <!-- </v-card-title> -->
</v-col>

            </v-card>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>

  <!-- <v-btn target="_blank" href="https://sc-kalender-fa94b.web.app/" class="mb-3 mx-auto">
              Als App testen
            </v-btn> -->


  </v-row>
  <div class="text-center">
  <v-btn target="_blank" href="https://sc-kalender-fa94b.web.app/" class="mb-3 mt-3 mx-auto">
              Als App testen
            </v-btn>
  </div>
  </v-container>
            <!-- <v-btn target="_blank" href="https://sc-kalender-fa94b.web.app/" class="mb-3 mx-auto">
              Als App testen
            </v-btn> -->
  </v-row>
      </v-col>
<v-col
        cols="12"
        md="6">
      <base-iframe>
      </base-iframe>
</v-col>
    </v-row>
    </section>

</template>

<script>
import BaseText from './base/BaseText.vue'
export default {
  components: { BaseText },
    computed: {
      title(){  
        return this.$store.getters['welcome_cms/getTitle']
      },
      description(){
        return this.$store.getters['service_cms/getDescription']
      },
      manual(){
        return this.$store.getters['welcome_cms/getManual']
      },
    }
    
}
</script>
